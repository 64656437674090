<template>
    <div class="calculator">
        <div @click="emit('getValue', '1')" class="calculator-item"> 1 </div>
        <div @click="emit('getValue', '2')" class="calculator-item"> 2 </div>
        <div @click="emit('getValue', '3')" class="calculator-item"> 3 </div>
        <div @click="emit('getValue', '4')" class="calculator-item"> 4 </div>
        <div @click="emit('getValue', '5')" class="calculator-item"> 5 </div>
        <div @click="emit('getValue', '6')" class="calculator-item"> 6 </div>
        <div @click="emit('getValue', '7')" class="calculator-item"> 7 </div>
        <div @click="emit('getValue', '8')" class="calculator-item"> 8 </div>
        <div @click="emit('getValue', '9')" class="calculator-item"> 9 </div>
        <div @click="emit('getValue', 'C')" class="calculator-item"> C </div>
        <div @click="emit('getValue', '0')" class="calculator-item"> 0 </div>
        <div @click="emit('getValue', 'ENTER')" class="calculator-item">
            <ion-icon name="enter-outline" style="font-size: 38px;"></ion-icon>
        </div>
    </div>
</template>

<script setup>
const emit = defineEmits(['getValue']);
</script>

<style lang="scss" scoped>
.calculator {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;

    .calculator-item {
        background-color: black;
        cursor: pointer;
        user-select: none;
        width: 100%;
        height: 70px;
        border-radius: 8px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px
    }
}
</style>