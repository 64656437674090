<template>
    <div class="content">

        <div>
            <div class="payment-infos">
                <div class="payment-info">
                    <h1 class="title">Tutar</h1>
                    <h1 class="value">₺100</h1>
                </div>
                <div class="payment-info">
                    <h1 class="title">Ödeme</h1>
                    <h1 class="value">₺50</h1>
                </div>
                <div class="payment-info">
                    <h1 class="title">Kalan</h1>
                    <h1 class="value">₺50</h1>
                </div>
            </div>

            <div class="payment-list">
                <h1 class="title">Alınan Ödemeler</h1>

                <div class="payment-list-row">
                    <div class="payment-item">NAKİT: ₺150</div>
                    <div class="payment-item">NAKİT: ₺75</div>
                    <div class="payment-item">KART: ₺75</div>
                </div>
            </div>

            <div class="actions">
                <div @click="state.paymentMethod = 'methods'"
                    :class="['action', { disabled: state.paymentMethod == 'methods' }]">
                    <ion-icon class="icon" name="card-outline"></ion-icon>
                    <h1 class="title">Ödeme Yöntemleri</h1>
                </div>
                <div @click="state.paymentMethod = 'calculator'"
                    :class="['action', { disabled: state.paymentMethod == 'calculator' }]">
                    <ion-icon class="icon" name="calculator-outline"></ion-icon>
                    <h1 class="title">Hesap Makinesi</h1>
                </div>
            </div>
        </div>

        <div class="methods" v-if="state.paymentMethod == 'methods'">
            <div class="method"> Nakit </div>
            <div class="method"> Kredi Kartı </div>
            <div class="method"> EFT-POS </div>
            <div class="method"> Cari </div>
            <div class="method"> Ödenmez </div>
            <div class="method"> İndirim </div>
            <div class="method"> Ticket </div>
            <div class="method"> Multinet </div>
            <div class="method"> Sodexo </div>
            <div class="method"> Oda Hesabı </div>
        </div>
        <BasicCalculator class="mb-2" v-if="state.paymentMethod == 'calculator'" />

        <button class="btn btn-primary" @click="printOrder">ÖDEME AL</button>
    </div>
</template>

<script setup>
import BasicCalculator from '@/components/BasicCalculator.vue';
import { reactive } from 'vue';

const state = reactive({
    paymentMethod: "methods"
});
</script>

<style lang="scss" scoped>
.content {
    color: black;

    .payment-infos {
        margin-bottom: 12px;
        width: 100%;
        display: flex;
        align-items: start;
        flex-direction: column;
        background-color: #eee;
        border-radius: 12px;

        .payment-info {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;
            padding: 12px;
            border-bottom: 1px solid #ccc;

            &:last-child {
                border-bottom: none;
            }

            .title {
                font-size: 16px;
                margin: 0px;
                padding: 0px;
            }

            .value {
                font-size: 17px;
                margin: 0px;
                padding: 0px;
            }
        }
    }

    .payment-list {
        margin-bottom: 12px;
        display: flex;
        align-items: start;
        flex-direction: column;

        .title {
            font-size: 15px;
            font-weight: bold;
        }

        &-row {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 8px;
            width: 100%;

            .payment-item {
                padding: 8px 12px;
                background-color: #e34a52;
                font-size: 14px;
                color: white;
                border-radius: 8px;
            }
        }
    }

    .actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .action {
            flex: 1;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height: 100px;

            &.disabled {
                opacity: .5;
            }

            .title {
                font-size: 14px;
                background-color: #2c2e41;
                padding: 8px 12px;
                border-radius: 8px;
                color: white;
            }

            .icon {
                font-size: 60px;
                color: #2c2e41;
            }
        }
    }

    .methods {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;

        .method {
            border: 1px solid #ccc;
            color: gray;
            padding: 8px;
            border-radius: 8px;
            font-size: 15px;
        }
    }
}
</style>