<template>
  <div v-if="props.show" class="c-modal">
    <div class="c-modal-content c-card">
      <input disabled type="text" class="main-input" v-model="customerCount" />
      <BasicCalculator @getValue="handleValue" :onEnter="onEnter" />
    </div>
  </div>
</template>
<script setup>
import { defineProps, defineEmits, ref, onMounted, watch } from 'vue';
import BasicCalculator from "@/components/BasicCalculator";

const props = defineProps({
  show: {
    type: Boolean,
    required: true,
  },
  value: {
    type: String,
    required: false
  }
});

const customerCount = ref(1);

const handleValue = value => {
  switch (value) {
    case "C":
      customerCount.value = "";
      break;
    case "ENTER":
      close();
      break;

    default:
      customerCount.value += value;
      break;
  }
}

const onEnter = () => {
  close();
}
const emit = defineEmits(['close', 'onSave']);

const close = () => {
  emit('close');
};
</script>
<style src="./CustomerCountModal.scss" lang="scss"></style>
